.body-content{
    min-height: 650px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    gap: 4px;
    overflow-x: auto;
    margin: 0 5vw;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.body-content::-webkit-scrollbar {
    display: none;
}
.container{

    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.today, .tomorrow, .after-tomorrow{
    font-size: 16px;
}

.header{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.book-buttons{
    display: flex;
    justify-content: center;
    gap: 4px;
    width: 90%;
    max-width: 475px;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 8px;
}
.time-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 40%;
    font-weight: bold;
    cursor: pointer;
    border: none;
    font-family: 'Roboto Medium', sans-serif;
    backdrop-filter: blur(8px);
    font-size: 17px;
    line-height: 22px;
    border-radius: var(--input-border-radius);
}
.inline-book{
    font-weight: 500;
    font-size: 13px;
    justify-content: center;
    letter-spacing: -0.43px;
}
.user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #E1E3E6;
    background: #F8F9FA;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    text-align: center;
    color: #000000;
    border-radius: 12px;
    min-height: 40px;
    flex: 2;
}
.user-info p{
    margin: 0;
}

.profile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px;
    align-items: center;
    border-radius: 12px;
    height: 40px;
    width: 90px;
    flex: 1;
    cursor: pointer;
}
.profile img{
    width: 20px;
}
.profile p{
    font-size: 17px;
    font-weight: 700;
}

.upper-hdr{
    width: 90%;
    max-width: 475px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    gap: 8px;
}










@media (min-width: 770px) {
    .body-content {
        margin: 0 auto;
        max-width: 700px;
    }
    .header{
        max-width: 700px;
        margin: 0 auto;
    }
}

@media (max-width: 393px) {
    .inline-book{
        font-size: 11px;
    }
    .body-content{
        margin: 0 2vw;
    }
    .profile p{
        font-size: 15px;
    }
}
@media(max-width:355px){
    .today, .tomorrow, .after-tomorrow{
        font-size: 14px;
    }
    .inline-book{
        font-size: 11px;
    }
    .profile p{
        font-size: 13px;
    }
}

@media (max-width: 318px) {
    .today, .tomorrow, .after-tomorrow{
        font-size: 13px;
    }
    .inline-book{
        font-size: 10px;
    }
}


