.wash-index.book-placeholder {
    border-radius: 8px;
    background: white;
    border: 1px solid #E1E3E6;
    width: 100%;
    height: 3vh;
}
.column{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    min-width: 0; /* Добавьте это, чтобы разрешить уменьшение ширины */
    min-height: 0; /* Добавьте это, чтобы разрешить уменьшение высоты */
}
.column *{
    width: 100%;
    text-align: center;
}
.timestamp{
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

}
.book-placeholder{
    border-radius: 8px;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    font-weight: bold;
    min-height: 40px;
}
.confirmed {
    animation: fill-green 0.5s ease-in-out forwards;
    background-color: var(--primary-button-color);
    color: white;
}
.confirmed .inline-time{
    color: #cde6ac;
}
.occupied {

    color: #f6a2a2;
    background: rgba(208, 44, 44, 0.15);
    backdrop-filter: blur(8px);
    border-radius: 8px;
}
.timestamp, .occupied {
    width: 100%;
    height: 6vh;
    box-sizing: border-box;
}
.occupied-room {
    font-size: 16px;
}
.main-time{
    font-size:16px;
}
.inline-time{
    font-weight: normal;
    font-size: 10px;
    color: rgba(83, 83, 83, 0.51);
}
.wash-index{
    border: 1px solid var(--stroke-color)
}
@media (min-width: 500px) {
    .user-info{
        width: 30vw;
    }
}

@media (max-width: 400px) {
    .main-time{
        font-size: 14px;
    }
}

@media (min-width: 920px) {
    .column{
        flex: 2;
    }
}
@media(max-height: 700px){
    .wash-index.book-placeholder {
        height: 3vh;
    }
    .timestamp, .occupied {
        height: 7vh;
    }
}

@media(max-width:420px){
    .innes{
        display: none;
    }
}

@keyframes fill-green {
    from {
        background-color: transparent;
        color: black;
    }
    to {
        background-color: var(--primary-button-color);
        color: white;
    }
}
