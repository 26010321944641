:root{
  --primary-button-color: #7FC02F;
  --secondary-button-color: rgb(103, 162, 30, 0.15);
  --invalid-input-stroke-color: #FF5555;
  --invalid-input-bg-color: rgba(208, 44, 44, 0.15);
}
#root {
  height: 100%;
  width: 100%;

}

html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  background-image: url('/public/bg_pattern.png');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #F2F3F5;
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
}

.App {
  height: 100%;
  width: 100%;
}
.btn {
  background-color: #8A8A8E;
  color: white;
  font-size: 17px;
  line-height: 22px;
  padding: 8px 24px;
  border-radius: var(--input-border-radius);
  font-weight: bold;
  cursor: pointer;
  border: none;
  font-family: 'Roboto Medium', sans-serif;
  backdrop-filter: blur(8px);
}
.primary{
  color: white;
  background-color: var(--primary-button-color);
}
.secondary{
  color: #67A21E;
  background-color: var(--secondary-button-color);
}
.cancel{
  background: rgba(208, 44, 44, 0.15);
 color: #FF5555;
}
.test-container{
  position: absolute;
  display: flex;
}
.test1001{
  padding: 5px;
}
.invalid-input{
  border-color: var(--invalid-input-stroke-color);
  background-color: var(--invalid-input-bg-color);
}