:root{
    --input-border-radius: 12px;
    --input-window-radius: 16px;
    --text-hint-color: #848484;
    --stroke-color: #E1E3E6;
    --input-padding: 14px 14px 12px 20px;
}
.content-table{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 20px 10px;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid var(--stroke-color);
    border-radius: var(--input-window-radius);
}
h1 {
    font-size: 24px;
    color: #000000;
    text-align: center;
    margin: 0;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 6;
}

label{
    color: var(--text-hint-color);
    letter-spacing: -0.43px;
    font-size: 12px;
    font-family: 'Roboto Regular ', sans-serif;
    line-height: 22px;
}


.container{
    width: 100%;
    height: 100%;
}
.room-input{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}
.room-input label {
    text-indent: 12px;
    font-family: 'Roboto', sans-serif;
    color: #848484;

}
.home-input{
    display: none;
}


.footer-info{
    text-align: center;
    margin: 0 20px;
}
.content-table .btn{
    margin: 0 20px;
}
input{
    background-color: #F2F3F5;
    border-radius: var(--input-border-radius);
    border: 1px solid var(--stroke-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Убирает стили браузера */
        padding: var(--input-padding);
        margin: 0;
        outline: none;
        font: inherit; /* Наследование семейства шрифтов и размеров от родителя */
        color: inherit; /* Наследование цвета текста */
        box-sizing: border-box;

}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
